// Custom bootstrap theming

// Site color variables
$color-cta: #FF4548;
$color-cta-shade: #FDF3EA;
$color-dark-bg: #1A1A1A;
$color-light-bg: #1A1A1A0D;
$color-p1: #3D3D3D;
$color-p1-shade: #ECECEC;
$color-p2: #767860;
$color-p2-shade: #F1F1EF;
$color-d1: #242423;
$color-d2: #50504F;
$color-d3: #7C7C7B;
$color-d4: #A7A7A7;
$color-d5: #D3D3D3;
$color-d6: #E9E9E9;
$color-d7: #F4F4F4;
$color-red-shade: #FFECED;
$color-yellow-shade: #F4E5D340;

$theme-colors: (
        "primary": $color-cta,
        "secondary": $color-d2,
);

// Breakpoints
$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

@import "../../node_modules/bootstrap/scss/bootstrap";
