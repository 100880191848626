// Custom bootstrap theming
@import "scss/custom";

// Google fonts
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;500;600&family=PT+Serif:wght@400;700&family=Source+Serif+Pro&display=swap');

@import "scss/common";
@import "scss/forms";
@import "scss/page-1";
@import "scss/page-2";
@import "scss/page-3";
@import "scss/page-4";

* {
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Outfit', sans-serif;
    background-color: white !important;
}

//button, .btn, a.custom-btn {
//    font-weight: 600 !important;
//    border-radius: 0 !important;
//}
//
//button.btn, a.custom-btn  {
//    padding: 12px 24px;
//    font-size: 1.1rem;
//}

h1, h2 {
    font-family: 'PT Serif', serif !important;
    font-weight: bold !important;
}

label {
    color: $color-d2;
    padding-bottom: 5px;
}

//input, select {
//    //border-radius: 0 !important;
//    border: 1px solid $color-d4;
//    height: 56px;
//    width: 100%;
//}

//textarea {
//    border-radius: 0 !important;
//    border: 1px solid $color-d4;
//    width: 100%;
//}

a {
    transition: all 0.3s ease;
    text-decoration: none;
    color: black;
}

a:hover, a:focus {
    color: $color-cta;
}

//button {
//    a:hover, a:focus {
//        color: unset;
//    }
//}

.our-projects-active{
    color: #D3D3D3 !important;
}