.hero-section {
    .about-us-image {
        height: 480px !important;
    }
}

.why-choose-us-section {
    .section-subtitle {
        font-size: 1.3rem;
        font-family: 'Outfit', sans-serif;
        color: $color-d2;
        font-weight: 400;
    }

    .card-wrapper {
        justify-content: space-between;
    }

    .custom-card {
        margin: 12px;

        h2 {
            font-size: 1.6rem;

            @media (min-width: $md) {
                font-size: 1.8rem;
            }
        }

        p {
            font-size: 0.9rem;
            color: $color-d3;

            @media (min-width: $md) {
                font-size: 1.1rem;
            }
        }

        //width: 500px;
        background-color: white;
        box-shadow: 0 25px 75px $color-d6;
        border-left: 3px solid $color-cta;
    }
}

.team-members-section {
    .team-member-card {
        cursor: pointer;

        .member-role {
            text-transform: uppercase;
            writing-mode: vertical-rl;
            text-orientation: mixed;
        }

        .media-wrapper {
            width: 100%;
            height: 320px;
            overflow: hidden;

            img {
                transition: all 0.3s ease;
                object-fit: cover;
                object-position: center center;
                -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
                filter: grayscale(100%);

                &:hover {
                    -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
                    filter: grayscale(0%);
                    transform: scale(1.1);
                    transform-origin: 50% 50%;
                }
            }
        }

        h3.member-name {
            font-size: 1.1rem;
            color: $color-d2;

            @media (min-width: $md) {
                font-size: 1.5rem;
            }
        }

        h6.member-role {
            font-size: 0.85rem;
            color: $color-d3;

            @media (min-width: $md) {
                font-size: 1.2rem;
            }
        }
    }
}

.schedule-meeting-section {
    h2 {
        color: $color-d1;
    }

    .section-subtitle {
        color: $color-d2;
        font-weight: 400;
        font-size: 1.15rem;
        line-height: 1.7;
    }

    ul {
        padding-left: 0;
        list-style: none;

        li {
            line-height: 2;

            &:before {
                content: "✓";
                padding-right: 12px;
            }
        }
    }

    .meeting-form-card {
        width: 100%;
        height: fit-content;
        background-color: white;
        box-shadow: 0 25px 75px $color-d6;
        font-family: 'Outfit', sans-serif;

        .form-title {
            font-size: 1.8rem;
            font-weight: 400;
            color: black;
        }

        .form {
            .p-multiselect-label-container {
                color: $color-d3;
                height: 24px;
            }

            .p-multiselect {
                border: none;
                border-bottom: 1.5px solid $color-d2;
            }

            .p-multiselect, .p-multiselect-label {
                padding: 0 !important;
            }

            .p-multiselect-trigger {
                padding: 0;
                color: $color-cta;
            }

            .card-input-element {
                display: none;
            }

            .card-input {
                transition: all 0.3s ease;
                border: 1px solid $color-d4;
                color: $color-d2;
            }

            .card-input:hover {
                background-color: $color-d7;
                cursor: pointer;
            }

            .card-input-element:checked + .card-input {
                border: 1px solid $color-cta;
                background-color: $color-cta;
                color: white;
                font-weight: 700;
            }

            h5 {
                font-weight: 400;
                font-size: 1rem;

                @media (min-width: $md) {
                    font-size: 1.3rem;
                }
            }

            button {
                border-radius: 0 !important;

                font-size: 1.15rem;

                &.greyed {
                    background-color: $color-p2;
                    border: 1px solid $color-p2;
                }
            }
        }
    }
}
