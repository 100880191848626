.hero-section {
    .hero-image-caption {
        text-align: center;
        color: $color-d6;
        font-size: 1.05rem;
        line-height: 1.5;

        @media (min-width: $md) {
            text-align: left;
            font-size: 1.12rem;
            line-height: 1.7;
        }
    }

    //.hero-content {
    //    .arrow-wrapper {
    //        margin-top: -40px !important;
    //    }
    //
    //    .hero-text {
    //        .followers-card {
    //            margin-left: 12px;
    //            margin-top: -24px;
    //        }
    //    }
    //}
    //
    //.hero-right {
    //    height: fit-content;
    //
    //    .media-wrapper {
    //        width: 100%;
    //        height: 520px;
    //    }
    //}
}

.our-partners-section {
    background-color: $color-light-bg;

    .section-text {
        font-weight: 400;
        color: $color-d3;
    }

    .partner-logo-bar {
        .partner-logo {
            width: 100%;
        }
    }
}

.contact-us-section-2 {
    .section-title {
        color: $color-d2;
        font-weight: 700 !important;
    }

    .contact-details-field {
        svg {
            fill: $color-cta;
            height: 36px !important;
        }

        .contact-details-wrapper {
            span, a {
                font-size: 1.1rem;
                font-weight: 400;
                color: $color-d3;

                @media (min-width: $md) {
                    font-size: 1.4rem;
                }
            }

            a:hover, a:focus, a:active {
                color: $color-cta;
            }
        }

        .contact-icon-wrapper {
            svg {
                font-size: 2rem;

                @media (min-width: $md) {
                    font-size: 2.5rem;
                }
            }
        }
    }

    .map-wrapper {

        iframe {
            min-height: 200px;
        }
    }
}

.get-in-touch-section {
    .section-title {
        font-size: 2.5rem;

        @media (min-width: $md) {
            font-size: 3.5rem;
        }

    }
}

.swal2-shown {
    .swal2-icon {
        margin-top: 2.25em !important;

    }

    .swal2-title {
        padding-top: 0.6em !important;
    }

    .swal2-popup {
        border-radius: 0 !important;
        padding-bottom: 1.8em !important;
    }

    .swal2-html-container{
        margin: 1em 1.5em .4em!important;
    }

    .swal2-styled.swal2-confirm {
        transition: all 0.3s ease;
        border-radius: 0 !important;
        display: block;
        background-color: $color-d4;
    }

    .swal2-styled.swal2-confirm:hover {
        background-color: $color-d3;
    }

    .swal2-styled.swal2-confirm:focus {
        box-shadow: 0 0 0 .2rem $color-d5;
    }
}
