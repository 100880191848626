// Page 1

// Hero section
.hero-bg-image {
    width: 100%;
    position: absolute;
    z-index: -1 !important;
}

.hero-section-home {
    min-height: fit-content;
    @media (min-width: $lg) {
        max-height: 100vh;
    }

    .social-media-icons {
        position: absolute;
        left: 0;
        bottom: 0;
        margin: 0;
        //width: 200px;
        //height: 64px;
        z-index: 10;
        //margin-bottom: 0;

        @media (min-width: $lg) {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 200px;
            height: 64px;
            margin: 0;
        }
    }

    button.play-button {
        z-index: 10;
        outline: none;
        background: none;
        border: none;
        padding: 0;
        margin: 0;
    }

    .play-button-icon {
        z-index: 10;
        cursor: pointer;
        transition: all 0.3s ease;
        border-radius: 50%;
        background-color: $color-p1;
        width: 68px;
        height: 68px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &:hover {
            background-color: $color-d2;
        }

        svg {
            transition: all 0.3s ease;
            width: 42px;
            height: 42px;
            fill: $color-p1-shade;
        }

        &:hover svg {
            fill: $color-red-shade;
        }
    }

    .hero-right {
        bottom: 0;
        right: 0;

        .media-wrapper {
            padding-top: 0;

            @media (min-width: $md) {
                padding-top: 125px;

            }
        }
    }

    //.hero-right .media-wrapper img {
    //    position: absolute;
    //    right: 0;
    //    bottom: 0;
    //    margin: auto;
    //    width: 92%;
    //}

    .overlay-row {
        margin-top: -130px;
        margin-left: -16px;
        height: fit-content;
        width: 100%;
        text-align: right;

        @media (min-width: $lg) {
            margin-left: 0;
            margin-top: -285px;
            text-align: center;
        }

        .hero-banner {
            display: inline-flex;
            flex-direction: row-reverse;
            justify-content: center;
            align-items: end;

            @media (min-width: $lg) {
                flex-direction: row;

            }

            .arrow {
                display: none;

                @media (min-width: $lg) {
                    display: inline-block;
                    padding-right: 16px;

                }

                @media (min-width: $xl) {
                    padding-right: 32px;

                }
            }

            .banner-box {
                background-color: white;
                z-index: 10;
                text-align: left;
                width: 160px;

                @media (min-width: $md) {
                    width: 240px;
                }

                @media (min-width: $lg) {
                    width: 300px;
                }

                h3 {
                    font-weight: 700;
                    line-height: 1.3;


                    @media (max-width: $md) {
                        font-size: 1.1em;
                    }
                }
            }
        }

        .social-media-icons {
            z-index: 10;

            @media (min-width: $lg) {
                //width: 218px;
            }
        }
    }


}

// What We Do section

// Gallery section
.gallery-section {

    .h-line {
        width: 75%;
    }

    .gallery-photos {
        text-align: center;

        &:first-child {
            margin-left: 0 !important;
        }


        .window {
            height: 180px;

            @media (min-width: $sm) {
                height: 360px;
            }

            @media (min-width: $md) {
                height: 480px;
            }

            @media (min-width: $lg) {
                height: 640px;
            }

            img {
                object-fit: cover;
            }
        }
    }
}

// Services section
.services-section {
    .section-subtitle {
        font-family: 'Outfit', sans-serif;
        font-size: 0.95rem;
        color: $color-d2;

        @media (min-width: $md) {
            font-size: 1.1rem;
        }
    }

    .container .row {
        padding-left: 12px;
        padding-right: 12px;
    }
}

// Featured projects section
.featured-projects-section {
    .container .row {
        padding-left: 2px;
        padding-right: 2px;
    }
}

// Our clients section
.clients-section {


    .row {
        @media (min-width: $sm) {
            height: fit-content;
        }

        @media (min-width: $md) {
            height: fit-content;
        }

        @media (min-width: $lg) {
            height: 440px;
        }

        @media (min-width: $xl) {
            height: 500px;
        }

        @media (min-width: $xxl) {
            height: 600px;
        }

    }

    .clients-map {
        @media (min-width: $md) {
            width: 48%;
            position: absolute;
            margin: auto;
            left: 0;
            max-height: 565px;
            max-width: 800px;
        }
    }
}

// Testimonials section
.testimonials-section {
    background-color: $color-yellow-shade;

    h2.section-title {
        color: $color-d2;
        font-size: 2.6rem;
        font-weight: 600;
        line-height: 1.1;

        @media (min-width: $md) {
            font-size: 3.2rem;
        }
    }


    h3.section-subtitle {
        font-family: 'Outfit', sans-serif;
        color: $color-d2;
        font-size: 2.4rem;
        line-height: 1;

        @media (min-width: $md) {
            font-size: 3.1rem;
        }
    }

    p {
        color: $color-d3;
    }

    .circles {
        margin-top: -42px;

        .circle {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: $color-d5;
            margin: 0 3px;
            display: inline-block;
        }

        .selected {
            background-color: $color-cta;
            border-radius: 5px;
            width: 20px !important;
        }
    }

    .testimonial-card {
        .testimonial-card-content {
            width: 100%;
            font-size: 0.95rem;
            font-weight: 500;
            color: $color-d3;
            line-height: 1.65;

            @media (min-width: $md) {
                width: 75%;
                font-size: 1.15rem;
            }

            .quotes {
                margin-top: -12px;
            }
        }

        .testimonial-card-author {
            font-size: 1.1rem;
            font-weight: 600;
            color: $color-d2;
            line-height: 1.5;

            @media (min-width: $md) {
                font-size: 1.5rem;
            }
        }
    }

    .swiper-pagination-bullet {
        width: 12px;
        height: 12px;
        transition: width 0.3s ease;
        background-color: $color-d3 !important;

        @media (min-width: $md) {
            width: 8px;
            height: 8px;
        }
    }

    .swiper-pagination-bullet-active {
        background-color: #767860 !important;
        width: 24px !important;
        border-radius: 6px;

        @media (min-width: $md) {
            border-radius: 4px;
            width: 16px !important;
        }
    }

    .swiper-button-prev, .swiper-button-next {
        display: none;
        color: #767860;
        font-size: 1.5rem;
        transition: color 0.3s ease;
        width: 50px;
        height: 50px;
        background-color: white;
        filter: drop-shadow(0px 18px 48px lighten($color-cta, 30%));

        @media (min-width: $md) {
            display: flex;
        }
    }

    .swiper-button-prev {
        transform: rotate(-45deg);
        margin-left: 32px;
    }

    .swiper-button-next {
        transform: rotate(45deg);
        margin-right: 32px;
    }

    .swiper-button-prev:after {
        content: '⃖';
        font-size: 3.5rem;
        margin-left: -18px;
        margin-top: 21px;
        transform: rotate(45deg);
    }

    .swiper-button-next:after {
        content: '⃗';
        font-size: 3.5rem;
        margin-left: 22px;
        margin-top: 20px;
        transform: rotate(-45deg);
    }
}
