.error-label {
    color: $color-cta;
}

.error-input {
    border: 1px solid $color-cta!important;
}

.p-invalid {
    border-color: #e24c4c!important;
}

.p-hidden-accessible {
    display: none !important;
}

.p-checkbox {
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    cursor: pointer;
    display: inline-flex;
    position: relative;
    user-select: none;
    vertical-align: bottom
}

.p-checkbox {
    height: 22px;
    width: 22px
}

.p-checkbox .p-checkbox-box {
    background: $color-d5;
    border-radius: 0 !important;
    color: $color-d2;
    height: 22px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow .2s;
    width: 22px
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
    color: #fff;
    font-size: 13px;
    transition-duration: .2s;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
    color: #fff;
    font-size: 13px;
    transition-duration: .15s
}

.p-checkbox .p-checkbox-box.p-highlight {
    background: $color-cta;
    border-color: $color-cta
}

.p-checkbox-box {
    align-items: center;
    display: flex;
    justify-content: center
}

.p-component {
    font-size: 1.1rem;
    font-weight: 500;
}

.p-component span {
    font-size: 1rem;
    font-weight: 500;
}

.p-component:hover {
    border-color: $color-d5;
}

.p-component, .p-component * {
    box-sizing: border-box
}

.p-connected-overlay-enter-done {
    transform: none
}

.p-highlight .p-checkbox .p-checkbox-box {
    border-color: #fff
}

.p-input-filled .p-multiselect {
    background-color: $color-d6
}

.p-input-filled .p-multiselect:not(.p-disabled).p-focus {
    background-color: $color-d6
}

.p-input-filled .p-multiselect:not(.p-disabled):hover {
    background-color: $color-d6
}

.p-inputtext, input, textarea {
    appearance: none;
    background: #fff;
    border: 1px solid $color-d4;
    border-radius: 0 !important;
    color: $color-d1;
    font-size: 1rem;
    height: 45px;
    padding: .25rem .75rem;
    transition: background-color 0.15s, border-color 0.15s, box-shadow .15s;
    width: 100%;

    @media (min-width: $md) {
        border: 1.2px solid $color-d4;
        padding: .5rem 1rem;
        height: 56px;
        font-size: 1.2rem;
    }
}

.p-inputtext:enabled:focus, input:enabled:focus, textarea:enabled:focus {
    border: 1.5px solid $color-cta;
    box-shadow: 0 0 0 .25rem lighten($color-cta, 30%);
    outline: 0 none;
    outline-offset: 0
}

textarea.p-inputtext, textarea {
    height: unset;
}

.p-inputwrapper-filled.p-multiselect.p-multiselect-clearable .p-multiselect-label {
    padding-right: 1.75rem;
}

.p-multiselect {
    cursor: pointer;
    background: #fff;
    border: 1.2px solid #ced4da;
    border-radius: 0 !important;
    transition: background-color 0.15s, border-color 0.15s, box-shadow .15s;
}

.p-multiselect {
    height: 45px;
    display: inline-flex;
    width: 100%;
    background: #fff;
    border: 1px solid $color-d4;
    border-radius: 0 !important;
    color: $color-d1;
    font-size: 1rem;

    @media (min-width: $md) {
        height: 56px;
        border: 1.2px solid $color-d4;
        font-size: 1.2rem;
    }
}

.p-multiselect-label-container {
    height: 56px;
    width: 100%;
    width: -webkit-fill-available;
    max-width: 85%;
    margin-top: auto;
    margin-bottom: auto;

    @media (min-width: $md) {
        max-width: 95%;
    }
}

.p-multiselect .p-multiselect-clear-icon {
    color: $color-d4;
    right: 2.357rem
}

.p-multiselect .p-multiselect-label {
    padding: .5rem 1rem;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow .2s;

    @media (min-width: $md) {
        font-size: 1.2rem;
        padding: .75rem 1.5rem;
    }
}

.p-multiselect .p-multiselect-label.p-placeholder {
    color: $color-d4;
}

.p-multiselect .p-multiselect-trigger {
    margin: auto;
    background: transparent;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    color: $color-d1;
    width: fit-content;
    padding-right: 1.5rem;
}

.p-multiselect-header {
    align-items: center;
    display: flex;
    justify-content: space-between
}

.p-multiselect-item {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-weight: 400;
    overflow: hidden;
    position: relative;
    white-space: nowrap
}

.p-multiselect-items {
    list-style-type: none;
    margin: 0;
    padding: 0
}

.p-multiselect-items-wrapper {
    overflow: auto
}

.p-multiselect-label {
    cursor: pointer;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.p-multiselect-panel {
    left: 0;
    position: absolute;
    top: 0
}

.p-multiselect-panel {
    background: #fff;
    border: none;
    border-radius: 0 !important;
    box-shadow: 0 18px 36px $color-d6;
    color: $color-d1;
}

.p-multiselect-panel .p-multiselect-header {
    display: none;
    background: #efefef;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: $color-d1;
    margin: 0;
    padding: .75rem 1.5rem
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
    display: none;
    background: transparent;
    border: 0 none;
    border-radius: 50%;
    color: $color-d1;
    height: 2rem;
    transition: box-shadow .15s;
    width: 2rem
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
    background: transparent;
    border-color: transparent;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
    border: 1.2px solid lighten($color-cta, 3%);
    box-shadow: 0 0 0 .2rem lighten($color-cta, 30%);
    outline: 0 none;
    outline-offset: 0
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container {
    margin: 0 .5rem
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
    padding-right: 1.75rem
}

.p-multiselect-panel .p-multiselect-items {
    padding: 1rem 0
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
    background: transparent;
    color: $color-d4;
    padding: .5rem 1.5rem
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    background: transparent;
    border: 0 none;
    border-radius: 0;
    color: $color-d1;
    margin: 0;
    padding: .5rem 1.5rem;
    transition: box-shadow .15s
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
    margin-right: 1rem
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
    background: #fff;
    color: $color-d1;
    font-weight: 600;
    margin: 0;
    padding: .75rem 1rem
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
    background: $color-d6;
    color: $color-d1
}

.p-multiselect-panel ::marker {
    font-variant-numeric: tabular-nums;
    text-align: start !important;
    text-align-last: start !important;
    text-indent: 0 !important;
    text-transform: none;
    unicode-bidi: isolate
}

.p-multiselect.p-multiselect-clearable .p-multiselect-label {
    padding-right: 2rem
}

.p-multiselect:not(.p-disabled):hover {
    border-color: $color-d5
}

.pi {
    font-size: 1rem
}

.pi {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-family: 'primeicons';
    font-feature-settings: normal;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 1;
    speak: none;
    text-transform: none
}

.pi-check:before {
    content: "\e909"
}

.pi:before {
    backface-visibility: hidden;
    --webkit-backface-visibility: hidden
}
